import React, { useState } from 'react'
// import { useParams, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

import ScrollToTopOnMount from '../../hooks/ScrollToTopOnMount'

import Modal from '../Modal'
import Filter from '../Filter'
import ScrolltoTopButton from '../ScrolltoTopButton'

const MainContainer = styled(motion.div)`
	width: 100%;
	padding: 3rem 5vw;
	
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Title = styled(motion.h1)`
	padding: 8vh 0 0 4vw;
	
	color: var(--text-color-regular);
	font-size: 5vw;
	letter-spacing: 4vw;
	text-transform: uppercase;
	
	@media only screen and (max-width: 768px) {
		font-size: 10vw;
    }	
`

const FilterContainer = styled(motion.div)`
	width: 100%;
	padding: 6vh 1vw;
	/* position: sticky; */
	/* top: 0; */
	background-color: var(--main-body-color);
	z-index: 10;
	opacity: 95%;
	
	display: flex;
	justify-content: center;
	align-items: center;
`

const PhotosContainer = styled(motion.div)`
	padding-bottom: 4vh;
	width: 100%;

	
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
	grid-gap: 3em;
	
	justify-content: center;
	
	@media only screen and (max-width: 768px) {
		grid-template-columns: repeat(auto-fit, minmax(90vw, 1fr));
		grid-gap: 1em;
    }
`

const ImgWrap = styled.div`
	/* padding controls height, will always be perfectly square regardless of width */
	padding: 50% 0;
	position: relative;
	overflow: hidden;

	// center image
	display: flex;
	align-items: center;
	justify-content: center;
`

const Img = styled(motion.img)`
	position: absolute;
    min-width: 100%;
    min-height: 100%;
	max-width: 150%;

	opacity: 80%;

	@media only screen and (max-width: 768px) {
		opacity: 90%;
    }
`

// ---------- ANIMATIONS ----------

const MContainerVariants = {
	exit: {
		opacity: 0,
		transition: {
			duration: 0.2
		}
	}
}

const photoVariant = {
	hidden: {
		opacity: 0
	},
	show: {
		opacity: 1,
		transition: {
			duration: 0.7,
			delay: 1
		}
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.2
		}
	}
}

const TitleVariant = {
	hidden: {
		opacity: 0,
		scale: 1.1,
	},

	show: {
		opacity: 1,
		scale: 1,
		transition: {
			delay: 1,
			duration: 0.5,
		}
	}
}

const ImgVariant = {
	hidden: {
		opacity: 0,
	},
	show: {
		opacity: 0.8,
		scale: 1,
		transition: {
			opacity: {
				duration: 0.7,
				delay: 1,
			},

			scale: {
				duration: 0.5
			}
		}
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.5
		}
	},
	hover: {
		scale: 1.1,
		transition: {
			duration: 0.5
		}
	},
}

const Gallery = ({ docs, allDocs, ImgFilter, setImgFilter }) => {

	let cdocs = docs[ImgFilter];

	let FilterCategories = Object.keys(docs).sort();

	const [selectedImg, setSelectedImg] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [direction, setDirection] = useState(1);

	// redirect back to portfolio page if URL not valid
	// if (!Object.keys(docs).includes(id)) {
	// 	return (
	// 		<Redirect to="/portfolio" />
	// 	)
	// }

	return (
		<>
			<ScrollToTopOnMount />
			{/* <GlobalStyle /> */}

			<MainContainer
				variants={MContainerVariants}
				initial="hidden"
				animate="show"
				exit="exit"
			>
				<Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} docs={cdocs} allDocs={allDocs} ImgFilter={ImgFilter} direction={direction} setDirection={setDirection} />

				<ScrolltoTopButton />

				<Title variants={TitleVariant}>Gallery</Title>

				<FilterContainer variants={TitleVariant}>
					<Filter ImgFilter={ImgFilter} setImgFilter={setImgFilter} FilterCategories={FilterCategories} />
				</FilterContainer>

				<PhotosContainer
					variants={photoVariant}
				>
					<AnimatePresence exitBeforeEnter>

						{cdocs ?

							cdocs.map((doc, index) => (
								<ImgWrap
									key={doc.id}
									onClick={() => { setSelectedImg(doc.url); setSelectedIndex(index); setDirection(1) }}
									whileHover={{ boxShadow: "0 0 8px #a8a8a8" }}
								>
									<Img
										key={doc.name}
										src={doc.url}
										alt={doc.name}
										variants={ImgVariant}
										initial="hidden"
										animate="show"
										exit="exit"
										whileHover="hover"
									/>
								</ImgWrap>
							))

							: allDocs ?
								allDocs.map((doc, index) => (
									<ImgWrap
										key={doc.name}
										onClick={() => { setSelectedImg(doc.url); setSelectedIndex(index); setDirection(1) }}
									>
										<Img
											key={doc.id}
											src={doc.url}
											alt={doc.name}
											variants={ImgVariant}
											initial="hidden"
											animate="show"
											exit="exit"
											whileHover="hover"
										/>
									</ImgWrap>
								))

								:

								<></>
						}

					</AnimatePresence>
				</PhotosContainer>
			</MainContainer>

		</>
	)
}


export default Gallery;