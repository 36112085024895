import firebase from 'firebase/app'
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics'

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyAuGrzX2p9Z5SHku6shEJICp9aev2WHruI",
  authDomain: "kyphotography-ddb8b.firebaseapp.com",
  databaseURL: "https://kyphotography-ddb8b.firebaseio.com",
  projectId: "kyphotography-ddb8b",
  storageBucket: "kyphotography-ddb8b.appspot.com",
  messagingSenderId: "671186406682",
  appId: "1:671186406682:web:d4e9d151a2fd82809df0a3",
  measurementId: "G-XCWBJ3KXS6"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const firebaseStorage = firebase.storage();
const firebaseFirestore = firebase.firestore();
const firebaseFunctions = firebase.functions();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { firebaseStorage, firebaseFirestore, firebaseFunctions, timestamp };
