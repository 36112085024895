import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const OpenSelect = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

    @media only screen and (max-width: 768px) {
        display: none;
    }
`

let Input = styled(motion.input).attrs({
    type: 'radio',
    name: 'categories'
})`

    //remove radio cirlces
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;

    visibility: hidden;

    :checked + label {
        color: var(--main-theme-dark);
    }

    @media only screen and (max-width: 768px) {
        pointer-events: none;
    }
`

const Label = styled(motion.label)`
    padding: 1vh 2vw;
	opacity: 70%;

    font-size: 1.3em;
	color: var(--text-color-regular);
	text-transform: capitalize;

    @media only screen and (max-width: 768px) {
        pointer-events: none;
    }
`

const Select = styled(motion.select)`
    display: none;
    text-transform: capitalize;

    background-color: var(--main-body-color);
    border: none;
   
    width: 80%;

    font-size: 1.3em;
    color: var(--text-color-regular);
    
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;

    color: var(--main-theme-dark);
    option:not(:checked) {
        color: var(--text-color-regular);
    }

    :focus {
        outline: none;
    }

    @media only screen and (max-width: 768px) {
        display: block;
    }

`

const Option = styled.option`
    background-color: var(--main-body-color);
`

// ---------- ANIMATIONS ----------


const Filter = ({ ImgFilter, setImgFilter, FilterCategories }) => {

    const changeCategory = (e) => {
        if (e.target.value === "all") {
            setImgFilter(null);
            return
        }
        setImgFilter(e.target.value);
    }

    return (
        <>
            <OpenSelect>
                <Input id="all" value="all" onChange={changeCategory} key="all" checked={ImgFilter === null ? true : false}></Input>
                <Label htmlFor="all" key="all_label" whileHover={{ scale: 1.1, opacity: 1 }}>all</Label>
                <Label>|</Label>
                {FilterCategories && FilterCategories.map((category, index) => (
                    <>
                        <Input id={category} value={category} onChange={changeCategory} key={category} checked={category === ImgFilter ? true : false}></Input>
                        <Label htmlFor={category} key={`${category}_label`} whileHover={{ scale: 1.1, opacity: 1 }}>{category}</Label>
                    </>
                ))}
            </OpenSelect>

            <Select onChange={changeCategory} whileHover={{ opacity: 1 }}>
                <Option value="all" selected={ImgFilter === null ? true : false}>all</Option>
                {FilterCategories && FilterCategories.map((category, index) => (
                    <Option value={category} selected={category === ImgFilter ? true : false} >{category}</Option>
                ))}
            </Select>
        </>
    )
}


export default Filter;