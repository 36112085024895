import React, { useState } from 'react';
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion"

//hooks
import useFirestore from './hooks/useFirestore'

//pages
import Home from './components/pages/Home'
import Gallery from './components/pages/Gallery';
import About from './components/pages/About';
import Contact from './components/pages/Contact';

//components 
import NavBar from './components/NavBar'
import Footer from './components/Footer'

function App() {
  const location = useLocation();

  // toggle for sidebar (close on route change)
  const [NavCheck, setNavCheck] = useState(false);

  const [ImgFilter, setImgFilter] = useState(null);

  const { docs, allDocs } = useFirestore("portfolioImages");

  return (
    <>
      <NavBar NavCheck={NavCheck} setNavCheck={setNavCheck} setImgFilter={setImgFilter} />

      <AnimatePresence exitBeforeEnter onExitComplete={() => setNavCheck(false)}>
        <Switch location={location} key={location.key}>
          <Route exact path="/">
            <Home docs={docs} setImgFilter={setImgFilter} />
          </Route>
          <Route path="/gallery">
            <Gallery docs={docs} allDocs={allDocs} ImgFilter={ImgFilter} setImgFilter={setImgFilter} />
            <Footer />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>

          {/* redirect to home if invalid page */}
          <Route path="*" >
            <Redirect to="/" />
          </Route>
        </Switch>
      </AnimatePresence>
    </>
  );
}

export default App;
