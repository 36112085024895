import React from 'react'
import { Link } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { motion } from 'framer-motion'

import ScrollToTopOnMount from '../../hooks/ScrollToTopOnMount'

import Splash from '../Splash'
import Portfolio from '../Portfolio'

// disable mobile browser hiding url bar 
const GlobalStyle = createGlobalStyle`
    @media only screen and (max-width: 768px) {
        body,html {
            overflow: hidden;
        }
    }
`

const MainContainer = styled(motion.div)`
    height: 100%;

    @media only screen and (max-width: 768px) {
        overflow-y: scroll;
    }
`

const Home = ({ docs, setImgFilter }) => {
    return (
        <>
            <ScrollToTopOnMount />
            <GlobalStyle /> 
            
            <MainContainer>
                <Splash />
                <Portfolio docs={docs} setImgFilter={setImgFilter} />
            </MainContainer>
        </>

    )
}

export default Home;