import React from 'react'
import { Link } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { motion } from 'framer-motion'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import ProfilePicture from '../../assets/images/profile.png'
import ScrollToTopOnMount from '../../hooks/ScrollToTopOnMount'

// disable mobile browser hiding url bar 
const GlobalStyle = createGlobalStyle`
    @media only screen and (max-width: 768px) {
        body,html {
            overflow: hidden;
        }
    }
`

const MainContainer = styled(motion.div)`
    min-height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;

    background: linear-gradient(135deg, var(--main-theme-lightest) 0%, var(--main-theme-regular) 40%, var(--main-theme-dark) 100%);


    @media only screen and (max-width: 768px) {
        // enable mobile scrolling
        height: 100%;
        min-height: 0;
		overflow-y: scroll;

        flex-direction: column;
    }
`
const LeftContainer = styled.div`
    width: 50%;

    background-image: url(${ProfilePicture});
    background-repeat: no-repeat;
    background-attachment: local;
    background-position: bottom right;
    background-size: auto 85%;
    
    @media only screen and (max-width: 768px) {
        width: 100%;
        min-height: 100%;
        background-position: 100% 30%;
        background-attachment: fixed;
        background-size: auto 60%;
    }
`

const RightContainer = styled(motion.div)`
    width: 50%;
    padding: 18vh 10vw 0 0;

    display: flex;
    flex-direction: column;
    
    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 10vh 8vw 5vh 8vw;
        background-color: var(--main-body-color);;
        color: var(--text-color-dark);
    }
`

const Header = styled(motion.h1)`
    font-size: 4rem;
    margin-bottom: 1rem;
    
    color: var(--text-color-light);
    
    @media only screen and (max-width: 768px) {
        margin-bottom: 2rem;
        color: var(--text-color-dark);
    }
`

const Paragraph = styled(motion.p)`
    margin-bottom: 1rem;

    color: var(--text-color-light);
    
    @media only screen and (max-width: 768px) {
        color: var(--text-color-dark);
    }
`

const PageLink = styled(Link)`
    align-self: flex-end;

    text-decoration: none;

    :link, :visited {
        color: var(--text-color-dark)
    }
`

const Button = styled(motion.button)`
    padding: 1em;
    margin: 3rem 0 3rem 0;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.4em;
    color: var(--text-color-dark);
    
    background-color: var(--text-color-light);
    border: none;
    border-radius: 5px;
    
    
    :focus {
        outline: none;
    }
    
    transition: color 0.2s;
    @media only screen and (max-width: 768px) {
        background-color: var(--main-theme-light);

        :hover {
            color: var(--text-color-light);
        }
    }
`

const FaArrow = styled(FontAwesomeIcon)`
    font-size: 1em;
    margin-left: 0.5em;
`

// ---------- ANIMATIONS ----------

const MContainerVariants = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            delay: 1,
            duration: 0.5,
            staggerChildren: 0.1,
            when: "beforeChildren"
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.2,
        }
    }
}



const RightChildrenVariants = {
    hidden: {
        opacity: 0,
        x: -100
    },
    show: {
        opacity: 0.9,
        x: 0,
        transition: {
            duration: 0.5,
        }
    },
    exit: {
        opacity: 0,
        x: 50,
        transition: {
            duration: 0.2
        }
    }
}



const About = () => {
    return (
        <>
            <ScrollToTopOnMount />
            <GlobalStyle /> 

            <MainContainer
                variants={MContainerVariants}
                initial="hidden"
                animate="show"
                exit="exit"
            >
                <LeftContainer />
                <RightContainer>
                    <Header variants={RightChildrenVariants}>Hi! I'm Ken</Header>
                    <Paragraph variants={RightChildrenVariants}>Imagine a world where we are afforded the opportunities to create stories, not just for yourself but for your friends, families and the people around you. I want to inspire you to create your own crazy stories. Or better yet, be part of the process for something exciting.</Paragraph>
                    <Paragraph variants={RightChildrenVariants}>I see photography only as a trigger or prompt for the good times in the past and hopefully more in the future. The best photos are those that were never intended, the ones that aren't perfect and the ones that aren't staged. Let me be clear, I do NOT want to take photos for you. I want to be part of something bigger.</Paragraph>
                    <Paragraph variants={RightChildrenVariants}>Having said that, if you would like some of my artwork to add to your home decor, cafe or local business - please contact me and we can arrange for this to happen!</Paragraph>
                    <PageLink to="/contact">
                        <Button variants={RightChildrenVariants} whileHover={{ scale: 1.1 }}>Get In Touch
                            <FaArrow icon={faChevronRight} />
                        </Button>
                    </PageLink>
                </RightContainer>
            </MainContainer>
        </>

    )
}

export default About;