import React, { useState } from 'react';
import styled from 'styled-components'
import { motion } from 'framer-motion'

var date = new Date();

const Foot = styled.footer`
    position: fixed;
    z-index: 20;
    
    // Hide and reveal footer on scroll
    /* transition: bottom 0.3s;
    ${props => {
        if (props.hideFooter) {
            return `bottom: 0;`;
        } else {
            return `bottom: -50vh;`;
        }
    }} */

    bottom: 0;

    width: 100%;
    min-height: 4vh;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow:  0 0 8px #afafaf;
    background-color: var(--main-body-color);
    opacity: 0.95;

    @media only screen and (max-width: 768px) {
        bottom: 0;
    }
`

const Text = styled(motion.span)`
    color: var(--text-color-dark);
`

// ---------- ANIMATIONS ----------

const textVariant = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            delay: 0.5,
            duration: 0.5
        }
    }
}

const Footer = () => {

    // useEffect(() => {
    //     // Anything in here is fired on component mount.
    //     return () => {
    //         // Anything in here is fired on component unmount.
    //         window.removeEventListener('scroll', footerHide);
    //     }
    // }, [])


    // Hide and reveal footer on scroll
    const [scrollFooter, setScroll] = useState(true);

    // window.addEventListener('scroll', footerHide);

    // var prevScrollpos = window.pageYOffset;
    // function footerHide() {
    //     var currentScrollPos = window.pageYOffset;
    //     if (prevScrollpos > currentScrollPos) {
    //         setScroll(true);
    //     } else {
    //         setScroll(false);
    //     }
    //     prevScrollpos = currentScrollPos;
    // }

    return (
        <Foot hideFooter={scrollFooter}>
            <Text
                variants={textVariant}
                initial="hidden"
                animate="visible"
            >
                Ken Ye · {date.getFullYear()}
            </Text>
        </Foot>
    )
}


export default Footer;