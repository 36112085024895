import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

import ScrollToTopOnMount from '../../hooks/ScrollToTopOnMount'

import ContactForm from '../ContactForm'

const MainContainer = styled(motion.div)`
    min-height: 100%;
    width: 100%;

    display: flex;

    @media only screen and (max-width: 768px) {
        /* height: 100%;
		overflow-y: scroll;
         */
        flex-direction: column;
    }
`
const LeftContainer = styled.div`
    width: 50%;
    padding: 20vh 6vw 5vh 10vw;
    
    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 20vh 8vw 0 8vw;
    }
`

const RightContainer = styled.div`
    width: 50%;
    padding: 22vh 10vw 5vh 6vw;

    display: flex;
    flex-direction: column;


    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 20vh 8vw 10vh 8vw;
    }
`

const Header = styled.h1`
    font-size: 4em;
    margin-bottom: 2rem;
    color: var(--text-color-dark);
`
const SecondaryHeader = styled.h1`
    color: var(--text-color-dark);
`

const Paragraph = styled.p`
    margin-bottom: 1rem;
    color: var(--text-color-dark);
`

const Form = styled.form`
`

const FaInstagram = styled(FontAwesomeIcon)`
    font-size: 2em;
    margin-left: 0.5em;
`

const Button = styled(motion.button)`
    padding: 1em;
    margin-top: 3rem;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--text-color-dark);


    background-color: var(--main-theme-light);
    border: none;
    border-radius: 5px;

    :focus {
        outline: none;
    }

    transition: color 0.2s;
    :hover {
        color: var(--text-color-light);
    }
`

// ---------- ANIMATIONS ----------

const MContainerVariants = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            duration: 0.5,
            delay: 1,
            staggerChildren: 0.2,
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.2,
        }
    }
}


const Contact = () => {
    return (
        <>
            <ScrollToTopOnMount />

            <MainContainer
                variants={MContainerVariants}
                initial="hidden"
                animate="show"
                exit="exit"
            >
                <LeftContainer>
                    <Header>Get in Touch!</Header>
                    <Paragraph>Got a crazy idea? Want to collab? Order a print? Ask me questions?</Paragraph>
                    <Paragraph>Contact me via Instagram or email through the form below and I'll get back to you!</Paragraph>

                    <Form action="https://www.instagram.com/ken.ye" method="get" target="_blank">
                        <Button type="submit" whileHover={{ scale: 1.1 }}>Follow me on Instagram
                            <FaInstagram icon={faInstagram} />
                        </Button>
                    </Form>
                </LeftContainer>

                <RightContainer>
                    <SecondaryHeader>Shoot Me A Message!</SecondaryHeader>
                    <ContactForm />
                </RightContainer>

            </MainContainer>
        </>

    )
}

export default Contact;