import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'

const Button = styled(motion.button)`
    position: fixed;
    z-index: 10;
    bottom: 3.5em;
    right: 1em;

    cursor: pointer;

    border: none;
    border-radius: 50%;
    background-color: transparent;

    @media only screen and (max-width: 768px) {
        padding: 1vmax;
        right: auto;
    }
`

const FaArrow = styled(FontAwesomeIcon)`
    padding: 2vmin;
    font-size: 12vmin;
    color: var(--text-color-dark);
    
    @media only screen and (max-width: 768px) {
        padding: 1vmax;
        font-size: 8vmax;
    }
`

// ---------- ANIMATIONS ----------

const ButtonVariants = {
    hidden: {
        opacity: 0,
        transition:
        {
            duration: 0.3,
        }
    },
    show: {
        opacity: 1,
        transition:
        {
            duration: 0.3,
        }
    },
    hover: {
        scale: 1.1
    }
}

const ScrolltoTopButton = () => {

    useEffect(() => {
        // onMount
        window.addEventListener('scroll', displayScrollButton);
        return () => {
            // unMount
            window.removeEventListener('scroll', displayScrollButton);
        }
    }, [])

    const [displayScroll, setDisplayScroll] = useState(false);

    function displayScrollButton() {
        if (window.pageYOffset > 200) {
            setDisplayScroll(true);
        } else {
            setDisplayScroll(false);
        }
    };

    return (
        <AnimatePresence>
            {displayScroll &&
                <Button
                    onClick={() => { window.scrollTo(0, 0) }}
                    variants={ButtonVariants}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    whileHover="hover"
                >
                    <FaArrow icon={faChevronCircleUp} />
                </Button>
            }
        </AnimatePresence>
    )
}


export default ScrolltoTopButton;