import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import SplashImage from '../assets/images/splashWallpaper.jpg'
import SplashImageMobile from '../assets/images/splashWallpaperMobile.jpg'

const MainContainer = styled(motion.div)`
    height: 100%;
    width: 100%;
    opacity: 0.9;

    display: flex;
    flex-direction: column;
	justify-content: center;
    align-items: flex-end;

    box-shadow: 0 0 100px #a8a8a8;

    overflow-x: hidden;

    background: url(${SplashImage});
    background-size: cover;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-position: right top;
    
    @media only screen and (max-width: 768px) {
        background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.1)), url(${SplashImageMobile});
        background-position: center center;
        background-size: cover;
        align-items: center;
    }
`

const TextContainer = styled(motion.div)`
    height: 70%;
    width: 40%;
    min-width: 600px;
    padding: 10vh 5vw 0 0;
    flex-grow: 1;
    
    opacity: 0.9;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    
    @media only screen and (max-width: 768px) {
        width: 100%;
        min-width: 100%;
        padding: 10vh 8vw 0 8vw;
        align-items: center;

        /* backdrop-filter: blur(2px); */
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
        padding: 20vh 8vw 0 8vw;
        align-items: center;

        /* backdrop-filter: blur(2px); */
    }
    `

const ButtonContainer = styled(motion.div)`
    height: 30%;
    width: 650px;
    padding-right: 5vw;
    flex-grow: 1;

    opacity: 0.9;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    
    
    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 0 8vw 0 8vw;
        align-items: center;
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
        padding: 8vh 8vw 10vh 8vw;
        align-items: center;
    }
`

const Header = styled(motion.h1)`
    font-size: 5em;
    margin-bottom: 2rem;
	text-align: right;
	
	color: var(--text-color-light);
	opacity: 70%;
	
	@media only screen and (max-width: 768px) {
        font-size: 3.5em;
		text-align: center;
    }
`

const SecondaryHeader = styled(motion.h2)`
    font-size: 2em;
    margin-bottom: 0.5rem;
	text-align: right;
    
	color: var(--text-color-light);
	opacity: 70%;


	@media only screen and (max-width: 768px) {
        font-size: 1.5em;
		text-align: center;
    }
`

const TertiaryHeader = styled(motion.h2)`
    font-size: 1.5em;
    margin-bottom: 2rem;
	text-align: right;
    
	color: var(--text-color-light);
	opacity: 70%;


	@media only screen and (max-width: 768px) {
        font-size: 1em;
		text-align: center;
    }
`


const Button = styled(motion.button)`
    font-size: 1.4em;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    color: var(--text-color-light);
    background-color: transparent;
    border: none;
    
    
    cursor: pointer;
    
    :focus {
        outline: none;
    }
`

const FaArrow = styled(FontAwesomeIcon)`
    margin-top: 1vh;
    font-size: 1em;
`
// ---------- ANIMATIONS ----------

const MContainerVariants = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 0.9,
        transition: {
            delay: 1,
            duration: 0.5,
            staggerChildren: 0.1,
            when: "beforeChildren"

        }
    },
    exit: {
        opacity: 0,
        transition: {
            duation: 0.2,
        }
    }
}



const ObjectChildrenVariants = {
    hidden: {
        opacity: 0,
        x: 200,
    },
    show: {
        opacity: 0.9,
        x: 0,
        transition: {
            duration: 0.5,
            // delay: 0.5
        }
    },
    exit: {
        opacity: 0,
        x: -200,
        transition: {
            duation: 0.2,
        }
    },
}

const Home = () => {

    const ViewWork = () => {
        document.querySelector("#portfolio").scrollIntoView();
    }

    return (
        <MainContainer
            variants={MContainerVariants}
            initial="hidden"
            animate="show"
            exit="exit"
        >

            <TextContainer>
                <Header variants={ObjectChildrenVariants}>Hi! I'm Ken</Header>
                <SecondaryHeader variants={ObjectChildrenVariants}>Welcome to my Passion Project</SecondaryHeader>
                <TertiaryHeader variants={ObjectChildrenVariants}>A combination of photography and some epic ideas. Currently creating stories in Radelaide, Australia.</TertiaryHeader>
            </TextContainer>

            <ButtonContainer variants={ObjectChildrenVariants}>
                <Button
                    variants={ObjectChildrenVariants}
                    whileHover={{ scale: 1.1, opacity: 1 }}
                    onClick={ViewWork}
                >
                    View My Work
                        <FaArrow icon={faChevronDown} />
                </Button>
            </ButtonContainer>

        </MainContainer>
    )
}

export default Home;