import { useState, useEffect } from 'react';
import { firebaseFirestore } from '../firebase/config';

const useFirestore = (collection) => {
    const [docs, setDocs] = useState({});
    const [allDocs, setAllDocs] = useState([]);

    useEffect(() => {
        const unsub = firebaseFirestore.collection(collection)
            .orderBy('createdAt', 'desc')
            .onSnapshot((snap) => {
                let documents = {};
                let allDocuments = [];

                snap.forEach(doc => {
                    if (!documents.hasOwnProperty(doc.data().category)) {
                        documents[doc.data().category.toLowerCase()] = [];
                        documents[doc.data().category.toLowerCase()].push({ ...doc.data(), id: doc.id });
                    } else {
                        documents[doc.data().category.toLowerCase()].push({ ...doc.data(), id: doc.id });
                    }
                    allDocuments.push({ ...doc.data(), id: doc.id });
                });
                
                setDocs(documents);

                allDocuments = shuffle(allDocuments);
                setAllDocs(allDocuments);
            }, (err) => {
                console.log(err);
            })
        return () => unsub();
    }, [collection]);

    // console.log("run firebase");
    return { docs, allDocs };
}

function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

export default useFirestore;