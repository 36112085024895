import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const PageLink = styled(Link)`
	height: 100%;
	text-decoration: none;
`

const ParallaxBackground = styled(motion.div)`
	/* Set a specific height */
	height: 100%;
	border-top: 0.5vh solid var(--text-color-light);

	/* Create the parallax scrolling effect */
	background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${props => props.wallpaper});

	background-size: cover;
	background-attachment: fixed;
	background-position: center center;
	background-repeat: no-repeat;

	opacity: 0.8;
`

const Heading = styled(motion.div)`

	/* Text positioning */
	z-index: 1;
	position: -webkit-sticky; /* Safari */
	position: sticky;
	top: 35%;
	width: 100%;
	text-align: center;
	padding: 10vh 0 10vh 4vw;

	/* Text font and size */
	color: var(--text-color-light);
	font-size: 3vw;
	letter-spacing: 4vw;
	text-transform: uppercase;


	@media only screen and (max-width: 768px) {
		font-size: 4vw;
		letter-spacing: 5vw;
		padding: 10vh 0 10vh 5vw;
	}
`

// ---------- ANIMATIONS ----------

const ParallaxBackgroundVarient = {
	hidden: {
		opacity: 0,
		transition: {
			duration: 0.2
		}
	},
	show: {
		opacity: 0.8,
		transition: {
			delay: 0.5,
			duration: 0.5,
			when: "beforeChildren"
		}
	}
}

const ChildrenVariant = {
	hidden: {
		opacity: 0,
		transition: {
			duration: 0.2
		}
	},
	show: {
		opacity: 1,
		transition: {
			duration: 0.5
		}
	}
}

const Portfolio = ({ docs, setImgFilter }) => {

	return (
		<>
			{docs && Object.keys(docs).sort().map(key => (
				<PageLink to={"/gallery"} key={key} onClick={() => setImgFilter(key)} id="portfolio">
					<ParallaxBackground
						wallpaper={docs[key][Math.floor(Math.random() * docs[key].length)].url}
						key={docs[key].name}
						variants={ParallaxBackgroundVarient}
						initial="hidden"
						animate="show"
						exit="hidden"
					>
						<Heading
							variants={ChildrenVariant}
						>
							{key}
						</Heading>
					</ParallaxBackground>
				</PageLink>
			))}
		</>
	)
}

export default Portfolio;