import React, { useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

import { firebaseFunctions } from '../firebase/config'

const Form = styled(motion.form)`
    display: flex;
    flex-direction: column;
`

const Input = styled(motion.input)`
    padding-bottom: 1em;
    margin-top: 3em;
    
    border: none;
    border-bottom: 1px solid grey;
    
    background-color: var(--main-body-color);
    color: var(--text-color-dark);

    :focus {
        outline: none;
        border-bottom: 2px solid var(--text-color-dark);
    }
`

let Submit = styled(Input).attrs({
    type: 'submit',
    value: 'Send'
})`
    --webkit-appearance: none;
    -mos-appearance: none;
    appearance: none;
    
    padding: 1em;
    display: flex;
    align-self: flex-start; 
    justify-content: center;

    cursor: pointer;

    background-color: ${props => props.color || "var(--main-theme-light)"};
    border-bottom: none;
    border-radius: 5px;

    :focus{
        border-bottom: none;
    }

    transition: color 0.2s;
    :hover {
        color: var(--text-color-light);
    }
`

const TextArea = styled.textarea.attrs({
})`
    margin-top: 3em;

    border: none;
    border-bottom: 1px solid grey;

    background-color: var(--main-body-color);
    color: var(--text-color-dark);

    resize: none;
    overflow: hidden;
    
    :focus {
        outline: none;
        border-bottom: 2px solid var(--text-color-dark);
    }
`

const SLabel = styled(motion.label)`
    margin-top: 2em;
    padding: 0.5em;

    border-radius: 5px;

    background-color: #bfffce;
    color: var(--text-color-dark);
`

const FLabel = styled(SLabel)`
    background-color: #ffbfbf;
`

// ---------- ANIMATIONS ----------

const statusMessage = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition:
        {
            duration: 0.1,
        }
    }
}

const ContactForm = ({ color }) => {

    //automatically grow text area as user input
    const TextAreaGrow = (e) => {
        if (e.target.clientHeight < e.target.scrollHeight) {
            e.target.style.height = e.target.scrollHeight.toString() + "px";
        }
    }

    const requestContact = firebaseFunctions.httpsCallable("requestContact");

    const [MessageSuccess, setMessageSuccess] = useState(null);
    const [MessageFail, setMessageFail] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();

        requestContact({
            name: e.target.name.value,
            email: e.target.email.value,
            message: e.target.message.value
        }).then((result) => {
            setMessageSuccess(true);
            setTimeout(() => {
                setMessageSuccess(null);
                e.target.reset();
            }, 5000);
        }).catch((error) => {
            setMessageFail(true);
            console.log(error.message);
            setTimeout(() => {
                setMessageFail(null);
            }, 5000);
        })
    }


    return (
        <Form onSubmit={handleSubmit} layout>
            <AnimatePresence>
                {MessageFail &&
                    <FLabel
                        variants={statusMessage}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                    >
                        Something went wrong! Please try again.
                    </FLabel>}
                {MessageSuccess &&
                    <SLabel
                        variants={statusMessage}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                    >
                        Message sent!
                    </SLabel>}
            </AnimatePresence>

            <Input type="text" name="name" placeholder="Name *" required></Input>
            <Input type="email" name="email" placeholder="Email *" required></Input>
            <TextArea type="text" name="message" placeholder="Message *" required onChange={TextAreaGrow}></TextArea>
            <Submit color={color} whileHover={{ scale: 1.1 }} />
        </Form>
    )
}

export default ContactForm;