import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { motion } from 'framer-motion'

const Nav = styled.nav`
    position: fixed;
    z-index: 40;

    //hide and reappear on scroll
    /* transition: top 0.3s;
    ${props => {
        if (props.hideNav) {
            return `
            top: 0;
      `;
        } else {
            return `
            top: -50vh;
      `;
        }
    }} */


    top: 0;
    width: 100%;
    min-height: 8vh;
    box-shadow:  0 0 8px #a8a8a8;

    display: flex;
    align-items: center;

    background-color: var(--main-body-color);
    opacity: 0.95;
    
    @media only screen and (max-width: 768px) {
        top: 0;
        justify-content: space-between;
    }
`


const LogoLink = styled(Link)`
    height: 100%;
    margin: 0 2vw 0 5vw;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    text-decoration: none;

    color: var(--text-color-dark);

    :link, :visited {
        color: var(--text-color-dark);
    }
`

const KYlogo = styled(motion.svg)`
    height: 3em;
    
    stroke: var(--text-color-dark);
    stroke-width: 10;
    
    @media only screen and (max-width: 768px) {
        height: 2.5em;
    }
`

const NavLinks = styled(motion.ul)`
    display: flex;
    justify-content: space-around;
    color: var(--text-color-dark);

    @media only screen and (max-width: 768px) {
        position: fixed;
        top: 0;
        right: 0;
        
        height: 100vh;
        width: auto;
        min-width: 50%;
        padding: 10vh 0 40vh 0;
        box-shadow:  0 0 8px #a8a8a8;

        background-color: inherit;

        display: flex;
        flex-direction: column;
        align-items: center;

        //hide and reappear on toggle
        transition: transform 0.3s ease-in;
        ${props => {
        if (props.toggle) {
            return `transform: translate(0%);`;
        } else {
            return `transform: translate(100%);`;
        }
    }}
    }
`

const PageLink = styled(Link)`
    padding: 0.6em 1em;

    text-decoration: none;
    color: inherit;

    :link, :visited, :active {
        color: inherit;
    }
`

const Li = styled(motion.li)`
    padding: 0.6em 0 0.6em 0;
    
    text-transform: uppercase;
    list-style: none;
    color: inherit;

    // underline animation
    background-image: linear-gradient(var(--main-theme-dark), var(--main-theme-dark));
    background-size: 0% 0.1em;
    background-position-y: 100%;
    background-position-x: 50%;
    background-repeat: no-repeat;
    transition: background-size 0.2s ease-in-out;

    :hover, :focus, :active {
        background-size: 100% 0.1em;
    }
`

const Hamburger = styled(motion.div)`

    display: none;
    cursor: pointer;
    padding: 0 5vw 0 3vw;

    div {
        width: 25px;
        height: 3px;
        margin: 5px;
        
        background-color: var(--text-color-dark);

        transition: all 0.3s ease; 
    }
    
    @media only screen and (max-width: 768px) {
        display: block;
    }

    

`
const Line1 = styled.div`
    ${props => {
        if (props.toggle) {
            return `transform: rotate(45deg) translate(5px, 6px);`;
        } else {
            return ``;
        }
    }}
`

const Line2 = styled.div`
    ${props => {
        if (props.toggle) {
            return `opacity: 0;`;
        } else {
            return ``;
        }
    }}
`

const Line3 = styled.div`
    ${props => {
        if (props.toggle) {
            return `transform: rotate(-45deg) translate(5px, -6px);`;
        } else {
            return ``;
        }
    }}
`

// ---------- ANIMATIONS ----------

const svgVariants = {
    // hidden: {
    //     scale: 0.5
    // },
    // visible: {
    //     scale: 1,
    //     transition: {
    //         duration: 1
    //     }
    // }
}

const pathVariants = {
    hidden: {
        opacity: 0,
        pathLength: 0,
        fill: "#fafafa"
    },
    visible: {
        opacity: 1,
        pathLength: 1,
        fill: "#444444",
        transition: {
            delay: 1,
            duration: 1,
            ease: "easeInOut",
            fill: {
                delay: 1.7,
                duration: 1
            }
        }
    }
};

const navVariant = {
    hidden: {
        opacity: 1
    },
    visible: {
        opacity: 1,
        transition: {
            delay: 1,
            staggerChildren: 0.1,
            when: "beforeChildren"
        }
    }
}

const textVariant = {
    hidden: {
        opacity: 0,
        x: -50,
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.5,
        }
    }
}

const hamburgerVariant = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            delay: 1.5,
            duration: 1
        }
    }
}

const NavBar = ({ NavCheck, setNavCheck, setImgFilter }) => {

    // Hide and reveal Navbar on scroll
    const [scrollNav, setScroll] = useState(true);

    // window.addEventListener('scroll', navHide);
    // var prevScrollpos = window.pageYOffset;
    // function navHide() {
    //     var currentScrollPos = window.pageYOffset;
    //     if (prevScrollpos > currentScrollPos) {
    //         setScroll(true);
    //     } else {
    //         setScroll(false);
    //         setNavCheck(false);
    //     }
    //     prevScrollpos = currentScrollPos;
    // }

    // toggle Hamburger Menu
    const navSlide = (e) => {
        setNavCheck(prevCheck => !prevCheck);
    }


    return (
        <Nav hideNav={scrollNav}>

            <LogoLink to="/">
                <KYlogo viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg"
                    variants={svgVariants}
                    initial="hidden"
                    animate="visible"
                >
                    <motion.path variants={pathVariants} d="m 534.3801,311.85907 c 62.7127,62.7127 125.4254,125.4254 188.13809,188.1381 -62.71269,62.7127 -125.42539,125.4254 -188.13809,188.1381 -62.7127,-62.7127 -125.4254,-125.4254 -188.1381,-188.1381 62.7127,-62.7127 125.4254,-125.4254 188.1381,-188.1381 z" />
                    <motion.path variants={pathVariants} d="m534.38 100.9-399.1 399.1 399.1 399.1h-133.03l-399.1-399.1 399.1-399.1z" />
                    <motion.path variants={pathVariants} d="m933.48 500-399.1-399.1h133.04l332.58 332.58z" />
                </KYlogo >
            </LogoLink>

            <NavLinks toggle={NavCheck}
                variants={navVariant}
                initial="hidden"
                animate="visible" >

                <Li variants={textVariant} whileHover={{ scale: 1.1 }}>
                    <PageLink to="/">Home</PageLink>
                </Li>
                <Li variants={textVariant} whileHover={{ scale: 1.1 }}>
                    <PageLink to="/gallery" onClick={() => setImgFilter(null)}>Gallery</PageLink>
                </Li>
                <Li variants={textVariant} whileHover={{ scale: 1.1 }}>
                    <PageLink to="/about">About Me</PageLink>
                </Li>
                <Li variants={textVariant} whileHover={{ scale: 1.1 }}>
                    <PageLink to="/contact">Get In Touch</PageLink>
                </Li>
            </NavLinks>

            <Hamburger onClick={navSlide}
                variants={hamburgerVariant}
                initial="hidden"
                animate="visible"
            >
                <Line1 toggle={NavCheck} />
                <Line2 toggle={NavCheck} />
                <Line3 toggle={NavCheck} />
            </Hamburger>
        </Nav>
    )
}

export default NavBar;