import React, { useEffect } from 'react'
// import { Link } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

// // enable mobile browser hiding url bar 
// const GlobalStyle = createGlobalStyle`
//     @media only screen and (max-width: 768px) {
//         body,html {
//             overflow: hidden;
//         }
//     }
// `

const MainContainer = styled(motion.div)`
    position: fixed;
    z-index: 60;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow:hidden;
    background: linear-gradient(rgba(0,0,0, 0.8), rgba(0,0,0,0.8));

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

const SlideshowButtons = styled(motion.button)`
    padding: 1vw;
    margin: 0 3vw;

    background: transparent;
    border: none;
    
    opacity: 0.6;
    
    :focus {
        outline: none;
    }
    
    @media only screen and (max-width: 768px) {
        display: none;
    }
    
`

const FaArrow = styled(FontAwesomeIcon)`
    font-size: 5vw;
    color: var(--text-color-light);
`


const MobileButtons = styled.div`
    display: none;
    
    @media only screen and (max-width: 768px) {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 8%;
    }
`

const MobileSlideshowButtons = styled(SlideshowButtons)`
    @media only screen and (max-width: 768px) {
        display: block;
        padding: 0 5vw;
    }
`

const FullSizeImg = styled(motion.img)`
    max-height: 80%;
    max-width: 70%;
    
    @media only screen and (max-width: 768px) {
        max-width: 90%;
        max-height: 70%;
    }
`

// ---------- ANIMATIONS ----------

const backdrop = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition:
        {
            duration: 0.1,
            when: "beforeChildren"
        }
    },
    exit: {
        opacity: 0,
    },
}

const img = {
    hidden: (direction) => ({
        scale: 0.8,
        x: direction > 0 ? 200 : -200,
        opacity: 0,

    }),
    show: {
        scale: 1,
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: (direction) => ({
        scale: 0.8,
        x: direction < 0 ? 200 : -200,
        opacity: 0
    })

}

const buttons = {
    hoverLeft: {
        scale: 1.1,
        opacity: 1,
        x: -15,
    },
    hoverRight: {
        scale: 1.1,
        opacity: 1,
        x: 15,
    }

}


const Modal = ({ selectedImg, setSelectedImg, selectedIndex, setSelectedIndex, docs, allDocs, ImgFilter, direction, setDirection }) => {

    useEffect(() => {
        // Anything in here is fired on component mount.
        return () => {
            // Anything in here is fired on component unmount.
            document.onkeydown = null;
        }
    }, [])
    
    
    const handleExit = (e) => {
        if (e.target.children.length > 2) {
            setDirection(-1);
            setSelectedImg(null);
            setSelectedIndex(null);
        }
    }


    const previousImage = (e) => {
        setDirection(-1);
        if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
            return;
        }

        if (selectedIndex === 0 && ImgFilter) {
            setSelectedIndex(docs.length - 1);
        } else {
            setSelectedIndex(allDocs.length - 1);
        }
    }

    const nextImage = (e) => {
        setDirection(1);
        if (ImgFilter) {
            if (selectedIndex < docs.length - 1) {
                setSelectedIndex(selectedIndex + 1);
            }

            if (selectedIndex === docs.length - 1) {
                setSelectedIndex(0);
            }
        }
        else {
            if (selectedIndex < allDocs.length - 1) {
                setSelectedIndex(selectedIndex + 1);
            }

            if (selectedIndex === allDocs.length - 1) {
                setSelectedIndex(0);
            }
        }
    }

    document.onkeydown = function (e) {
        switch (e.keyCode) {
            case 37:
                previousImage();
                break;
            case 39:
                nextImage();
                break;
            case 27:
                setSelectedImg(null);
                setSelectedIndex(null);
                break;
            default:
                break;
        }
    };

    const swipePower = (offset, velocity) => {
        return Math.abs(offset) * velocity;
    }

    return (
        <AnimatePresence exitBeforeEnter>
            {selectedImg &&
                
                <MainContainer
                onClick={handleExit}
                variants={backdrop}
                initial="hidden"
                animate="show"
                exit="exit"
                >
                    {/* <GlobalStyle/> */}

                    <SlideshowButtons
                        onClick={previousImage}
                        variants={buttons}
                        whileHover="hoverLeft"
                    >
                        <FaArrow icon={faChevronLeft} />
                    </SlideshowButtons>

                    <FullSizeImg
                        key={ImgFilter ? docs[selectedIndex].url : allDocs[selectedIndex].url}
                        src={ImgFilter ? docs[selectedIndex].url : allDocs[selectedIndex].url}
                        alt={ImgFilter ? docs[selectedIndex].name : allDocs[selectedIndex].name}
                        custom={direction}
                        variants={img}
                        drag="x"
                        dragConstraints={{ left: 0, right: 0 }}
                        dragElastic={1}
                        onDragEnd={(e, { offset, velocity }) => {
                            const swipe = swipePower(offset.x, velocity.x);

                            if (swipe < -1000) {
                                nextImage();
                            } else if (swipe > 1000) {
                                previousImage();
                            }
                        }}
                    />

                    <SlideshowButtons
                        onClick={nextImage}
                        variants={buttons}
                        whileHover="hoverRight"
                    >
                        <FaArrow icon={faChevronRight} />
                    </SlideshowButtons>

                    <MobileButtons>
                        <MobileSlideshowButtons
                            onClick={previousImage}
                            variants={buttons}
                            whileHover="hoverLeft"
                        >
                            <FaArrow icon={faChevronLeft} />
                        </MobileSlideshowButtons>

                        <MobileSlideshowButtons
                            onClick={nextImage}
                            variants={buttons}
                            whileHover="hoverRight"
                        >
                            <FaArrow icon={faChevronRight} />
                        </MobileSlideshowButtons>
                    </MobileButtons>

                </MainContainer>
            }
        </AnimatePresence>
    )
}

export default Modal;